<template>
    <div class="m-about">
        <detail-nav :tabList="tabList" :curSite="curSite" @changeTab="changeTab" ref="detailNav"></detail-nav>
        <div class="m-content">
            <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10"
                 infinite-scroll-immediate-check="false">
                <ul class="rongyu-ul" v-if="typeId=='bzry'">
                    <li v-for="(item,index) in dataRes" @click="goBzryDetail(item)" :key="index">
                        <div class="ronyu-img">
                            <img :src="item.titleImage" alt="">
                        </div>
                        <p class="ronyu-text">{{item.title}}</p>
                    </li>
                </ul>
                <!-- 领导关怀 -->
                <ul class="m-news-info" v-if="typeId=='ldgh'">
                    <li v-for="(item,index) in dataRes" @click="goLdghDetail(item)" :key="index">
                        <div class="m-left-img">
                            <img :src="item.titleImage" alt="">
                        </div>
                        <div class="m-right-content">
                            <div class="m-right-title">
                                <h3>{{item.title}}</h3>
                            </div>
                            <p>{{item.year}}-{{item.monthAndDay}}</p>
                        </div>
                    </li>
                </ul>

                <!-- 商会简介 -->
                <div class="m-about-content content1" v-else-if="typeId=='shjj'">
                    <h3>{{dataRes.title}}</h3>
                    <div v-html="dataRes.shgkContent"></div>
                </div>
                <!-- 商会章程 -->
                <div class="m-about-content content1" v-else-if="typeId=='shzc'">
                    <h3>{{dataRes.title}}</h3>
                    <div v-html="dataRes.shgkContent"></div>
                </div>
				<!-- 组织架构 -->
				<div class="m-about-content content1" v-else-if="typeId=='zzjg'">
				    <h3>组织架构</h3>
				    <div class="zzjg-img">
						<img src="../../assets/img/zzjg.png" >
					</div>
				</div>
				<!-- 会长致辞 -->
				<div class="m-about-content  hzzc" v-else-if="typeId=='hzzc'">
				    <h3 class="m-title">会长致辞</h3>
				    <div class="m-content-text">
				    	<p>中国侨商联合会自成立以来，在中国侨联的指导下，
				    	紧紧围绕党和国家发展大局，锐意进取，不断创新，
				    	为国家经济发展、“一带一路”建设、公益慈善事业等作了大量工作，
				    	在海内外有着广泛的知名度和美誉度。
				    	</p>
				    	<p>新中国自成立以来，创造了世所罕见的经济发展及社会长期稳定的大国奇迹，
				    	中国人民在中国共产党领导下自力更生、艰苦创业、锐意改革、不断前进，
				    	把一个“一穷二白”的旧中国，建设成为世界第二大经济体，实现从站起来、
				    	富起来到强起来的伟大飞跃。
				    	</p>
				    	<p>回顾新中国的发展史，我们无比自豪，中国是世界和平的建设者、
				    	全球发展的贡献者、国际秩序的维护者。展望未来，我们信心百倍，
				    	作为大变局中的稳定力量，新时代中国将为世界繁荣进步作出更大贡献。
				    	</p>
				    	<p>中国的发展，给广大侨商提供了更加广阔的舞台。
				    	中国侨商联合会的各位会员都有着赤诚的爱国情怀、雄厚的经济实力、
				    	丰富的智力资源、广泛的商业网络，是中国发展不可缺少的重要力量。
				    	站在新时代的起点，我们豪情满怀，壮志在胸。
				    	我们将更加紧密地团结在以习近平总书记为核心的党中央周围，
				    	更加广泛深入地参与到中华民族伟大复兴进程中来，
				    	为中国的创新发展和“一带一路”建设、为促进两岸和平统一和保持港澳繁荣稳定、
				    	为中国和世界的交流合作贡献更多的智慧和力量。
				    	</p>
				    	<p>中国侨商联合会作为服务侨商的全国性社会组织，将发挥好桥梁和纽带作用，集思广益，
				    	不断创新和进取。中国侨商联合会将努力成为服务会员和国家发展大局平台的“搭建者”，
				    	实施“一带一路”战略的“推广者”，举办各类活动的“组织者”，会员投资合法权益的“维护者”，
				    	传递信息、交流合作的“沟通者”。
				    	</p>
				    	<p>我们相信，有党中央的高度重视，有中国侨联的正确领导和指导，
				    	有广大侨商会员的精诚合作和积极参与，中国侨商联合会一定能够抓住机遇，
				    	蓬勃发展，成为温馨和谐的“侨商之家”。
				    	</p>
				    	<p>衷心感谢社会各界人士一直以来给予侨商群体的关心和帮助。
				    	乘风破浪，任重道远。让我们携手同心，汇聚起海内外中华儿女的力量，
				    	同心共圆中华民族伟大复兴的中国梦，同心构建人类命运共同体的美好未来！
				    	</p>
				    </div>
				    <div class="m-content-bottom">
				    	<div class="nameList">
				    		<p>中国侨商联合会会长</p>
				    		<img src="../../assets/img/list-01.png" >
				    	</div>
				    	<div class="nameList">
				    		<p>中国侨商联合会会长</p>
				    		<img src="../../assets/img/list-02.png" >
				    	</div>
				    </div>
				</div>
            </div>
            <p v-if="noMore" class="null-data">{{nullText}}</p>
        </div>

    </div>
</template>

<script>
    import detailNav from "@/components/mobile/detailNav.vue";

    export default {
        name: "mobile_shgk",
        components: {
            detailNav
        },
        data() {
            return {
                curSite: {
                    name: "商会概况",
                    nameEn: 'Chamber of Commerce Introduction',
                    path: 'about'
                },
                navName: "",
                navNameEn: "",
                current: 0,
                tabList: [
                    {
                        typeId: 'shjj',
                        typeName: '商会简介',
                        typeNameEn: "ORIGINATOR",
                    },
					{
					    typeId: 'hzzc',
					    typeName: '会长致辞',  
					},
					{
					    typeId: 'ldgh',
					    typeName: "领导关怀",
					    typeNameEn: "FOUNDATION CHARTER",
					},
					{
					    typeId: 'bzry',
					    typeName: "表彰荣誉",
					    typeNameEn: "FOUNDATION STRUCTURE",
					},
					{
					    typeId: 'zzjg',
					    typeName: "组织架构",
					    
					}, 
                    {
                        typeId: 'shzc',
                        typeName: "商会章程",
                        typeNameEn: "FOUNDATION INTRODUCTION ",
                    }
					
                ],
                dataRes: [],
                ronyuList: [],
                typeId: 'shjj',
                busy: false,
                noMore: false,
                nullText: '',
            }
        },
        watch: {
            $route(to) {
                if (to.path == '/mobile/shgk') {
                    const typeId = this.$route.query.typeId;
                    if (typeId == 'bzry' || typeId == 'ldgh') {
                        this.busy = false
                        this.dataRes = []
                        this.current = 1
                    } else {
                        this.noMore = false;
                    }

                    this.getData(1, false)
                }
            }
        },
		created() {
			if (!/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						// console.log(123)
			        this.$router.push({
			            path: "/shgk",
						query:{
							typeId:this.$route.query.typeId
						}
			        });
			}
		},
        mounted() {
            this.navName = this.tabList[0].typeName;
            this.navNameEn = this.tabList[0].typeNameEn;
        },
        methods: {
            changeTab(item) {
                const typeId = item.typeId
                const url = this.$route.path;
                this.$router.push({path: url, query: {typeId: typeId}})
            },
            getData(pagination, flag) {
                this.typeId = this.$route.query.typeId
                switch (this.typeId) {
                    case 'shzc':
                        this.$http.shzcList().then(res => {
                            if (res.code == 200) {
                                this.dataRes = res.data
                            }
                        })
                        break;
                    case 'bzry':
                        this.$http.shbzryList({page: pagination, limit: 4}).then(res => {
                            if (res.code == 200) {
                                if (flag) {//如果flag为true则表示分页
                                    this.dataRes = this.dataRes.concat(res.data.records);  //concat数组串联进行合并
                                    if (res.data.records.length == 0) {  //如果数据加载完 那么禁用滚动时间 this.busy设置为true
                                        this.busy = true;
                                        this.noMore = true;
                                        this.nullText = '没有更多数据了';
                                    } else {
                                        this.busy = false;
                                        this.noMore = false;
                                    }
                                } else {//第一次进入页面 完全不需要数据拼接的
                                    this.dataRes = res.data.records;
                                    this.busy = false;
                                    this.nullText = '暂无数据';
                                }
                            }
                        })
                        break;
                    case 'ldgh':
                        this.$http.shldghList({page: pagination}).then(res => {
                            if (res.code == 200) {
                                res.data.records.forEach(function (item) {
                                    item.monthAndDay = item.monthAndDay.slice(0, 2) + "-" + item.monthAndDay.slice(4, 6)
                                })
                                // this.dataRes = res.data
                                if (flag) {//如果flag为true则表示分页
                                    this.dataRes = this.dataRes.concat(res.data.records);  //concat数组串联进行合并
                                    if (res.data.records.length == 0) {  //如果数据加载完 那么禁用滚动时间 this.busy设置为true
                                        this.busy = true;
                                        this.noMore = true;
                                        this.nullText = '没有更多数据了';
                                    } else {
                                        this.busy = false;
                                        this.noMore = false;
                                    }
                                } else {//第一次进入页面 完全不需要数据拼接的
                                    this.dataRes = res.data.records;
                                    this.busy = false;
                                    this.nullText = '暂无数据';
                                }
                            }
                        })
                        break;
						case 'zzjg':
						break;
						case 'hzzc':
						break;
                    default:
                        this.typeId = 'shjj'
                        this.$http.shjjList().then(res => {
                            if (res.code == 200) {
                                this.dataRes = res.data
                            }
                        })
                        break;
                }
            },
            // 跳转至详情页
            goBzryDetail(item) {
                this.$router.push({path: '/mobile/shgk/bzry/detail', query: {id: item.id}})
            },
            // 跳转至详情页
            goLdghDetail(item) {
                this.$router.push({path: '/mobile/shgk/ldgh/detail', query: {id: item.id}})
            },
            //荣誉展示
            loadMore() {
                if (this.typeId == 'bzry' || this.typeId == 'ldgh') {
                    this.noMore = true;
                }
                this.busy = true;
                this.nullText = '加载中...'
                setTimeout(() => {
                    this.current++;  //滚动之后加载第二页
                    this.getData(this.current, true)
                }, 500);

            },
        },
    }
</script>

<style scoped>
    .about-1 {
        display: block;
        width: 100%;
        margin-top: 2.33333333333rem;
    }

    .desc-logo {
        width: 14.1333333333rem;
        margin: 0 auto 2rem;
        display: block;
    }

    .rongyu-ul {
        display: flex;
        display: -webkit-flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .rongyu-ul li {
        width: 47%;
        /* margin-bottom: 1rem; */
    }

    .ronyu-img {
        width: 10.6666666667rem;
        width: 100%;
        padding: 1rem;
        height: 10.6666666667rem;
        border: 0.333333333333rem solid #f5f5f5;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ronyu-img img {
        max-width: 100%;
        max-height: 100%;
    }

    .ronyu-text {
        margin-top: .8rem;
        display: flex;
        align-items: center;
        height: 56px;
        line-height: 5vw;
        font-size: 4vw;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 0;
        color: #333333;
    }

    .dashiji {
        border-left: 0.1333rem solid #b82322;
    }

    .dashiji-month {
        color: #3f3f3f;
        font-size: 1.133rem;
        margin-bottom: 1rem;
        position: relative;
        margin-top: -.67rem;
    }

    .dashiji-month::after {
        content: "";
        position: absolute;
        left: -1.1rem;
        top: 50%;
        transform: translateY(-50%);
        width: 0.67rem;
        height: 0.67rem;
        background: #b92322;
        border-radius: 50%;
    }

    .dashiji-item {
        border: 1px solid transparent;
        margin-bottom: 2rem;
        padding-left: .67rem;
    }

    .dashiji-item p {
        font-size: 18px;
        color: #444;
        line-height: 28px;
        margin-bottom: 22px;
    }

    .m-about-content >>> img {
        max-width: 100%;
        height: auto;
        margin: 0 auto;
        display: block;
    }

    .m-news-info {
        overflow: hidden;
    }

    .m-news-info li {
        margin-bottom: 2rem;
        overflow: hidden;
        cursor: pointer;
    }

    .m-news-info li:last-child {
        border-bottom: none;
    }

    .m-left-img {
        float: left;
        margin-right: 1rem;
        width: 7.458rem;
        height: 5.082rem;
        border-radius: .264rem;
        overflow: hidden;
    }

    .m-left-img:hover img {
        transform: scale(1.2);
    }

    .m-left-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .5s ease-in-out;
        -webkit-transition: all .5s ease-in-out;
    }

    .m-right-content {

    }

    .m-right-title {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .67rem;
        text-align: left;
    }

    .m-right-title h3 {
        font-weight: normal;
        overflow: hidden;
        width: 100%;
        height: 3.1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #000000;
        font-size: 4.5vw;
        line-height: 1.55rem;
    }

    .m-right-content p {
        text-align: left;
        margin-bottom: 0;
        line-height: 1.3;
        color: #879399;
        font-size: .792rem;
    }

    .content1 {
        width: 87vw;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        text-align: left;
        color: #333333;
    }

    .content1 p {
        font-size: 4vw;
        color: #333333;
        line-height: 6vw;
        text-align: justify;
        margin-bottom: 6vw;
    }

    .content1 > div {
        margin-top: 6vw;
        color: #333333;
    }

    .content1 > h3 {
        text-align: center;
        color: #333333;
    }

    .title1 {
        font-size: 4.7vw !important;
        color: #333333;
        text-align: center;
    }

    .text-center {
        text-align: center;
    }
	.zzjg-img{
		width: 100%;
		height: auto;
	}
	/* 会长致辞 */
	.hzzc .m-title{
		text-align: center;
		font-size: 6vw;
		font-weight: normal;
		color: #000000;
		margin-bottom: 4vw;
	}
	.hzzc .m-content-text p {
		text-indent: 8vw;
		font-size: 4vw;
		color: #000000;
		line-height: 6vw;
		text-align: justify;
	}
	.hzzc .m-content-bottom{
		margin-top: 8vw;
		display: flex;
		justify-content: center;
	}
	.hzzc .m-content-bottom .nameList{
		display: flex;
		flex-direction: column;
	}
	.hzzc .m-content-bottom .nameList:nth-of-type(1){
		margin-right: 10vw;
	}
	.hzzc .m-content-bottom .nameList p {
		font-size: 4vw;
		color: #000000;
		margin-bottom: 8vw;
		text-align: center;
	}
	.hzzc .m-content-bottom .nameList img{
		width: 30vw;
		height: auto;
		display: block;
	}
</style>
